<template>
  <div id="app">
    <van-notice-bar
        left-icon="volume-o"
        :scrollable="false"
        text="请按时确认，超时视为无异议自动确认哦！"
    />

    <van-tabs v-model="active" sticky @click="onClick">
<!--      <van-tab v-for="(tab, index) in tabTitleList" :key="index" :title="tab.title" :name="tab.id">-->
<!--        <van-list-->
<!--            v-model="loading"-->
<!--            :finished="finished"-->
<!--            @load="onLoad"-->
<!--            finished-text="没有更多了哦~"-->
<!--            v-if="listData && listData.length>0"-->
<!--        >-->
<!--          <div class="white_box_1" v-for="(item, index) in listData" :key="index" @click="routeToDetail(item)">-->
<!--            <div style="display: flex">-->
<!--              <div style="flex: 1; font-size: 14px; line-height: 14px; color: #222222;">-->
<!--                {{ item.taskReservationConfirm ? item.taskReservationConfirm.name : '' }}-->
<!--              </div>-->
<!--              <label>{{ handleConfirmState(item.confirmState, item.taskReservationConfirm.state) }}</label>-->
<!--            </div>-->

<!--            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">-->
<!--              {{ '待确认人:' }}{{ item.businessCustomerUser ? item.businessCustomerUser.name : '' }}-->
<!--            </div>-->

<!--            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">-->
<!--              {{ '开始时间:' }}{{ item.taskReservationConfirm.startTime | moment('yyyy-MM-DD HH:mm:ss') }}-->
<!--            </div>-->

<!--            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">-->
<!--              {{ '截止时间:' }}{{ item.taskReservationConfirm.endTime | moment('yyyy-MM-DD HH:mm:ss') }}-->
<!--            </div>-->

<!--          </div>-->
<!--        </van-list>-->
<!--        <van-empty class="custom-image" v-if="!listData || listData.length===0" description="暂无报停餐确认数据"/>-->
<!--      </van-tab>-->

      <van-tab :title="'待确认'" :name="'1'">
        <van-list
            v-model="loading"
            :finished="finished"
            @load="onLoad"
            finished-text="没有更多了哦~"
            v-if="listData && listData.length>0"
        >
          <div class="white_box_1" v-for="(item, index) in listData" :key="index" @click="routeToDetail(item)">
            <div style="display: flex">
              <div style="flex: 1; font-size: 14px; line-height: 14px; color: #222222;">
                {{ item.taskReservationConfirm ? item.taskReservationConfirm.name : '' }}
              </div>
              <label>{{ handleConfirmState(item.confirmState, item.taskReservationConfirm.state) }}</label>
            </div>

            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
              {{ '待确认人:' }}{{ item.businessCustomerUser ? item.businessCustomerUser.name : '' }}
            </div>

            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
              {{ '开始时间:' }}{{ item.taskReservationConfirm.startTime | moment('yyyy-MM-DD HH:mm:ss') }}
            </div>

            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
              {{ '截止时间:' }}{{ item.taskReservationConfirm.endTime | moment('yyyy-MM-DD HH:mm:ss') }}
            </div>

          </div>
        </van-list>
        <van-empty class="custom-image" v-if="!listData || listData.length===0" description="暂无报停餐确认数据"/>
      </van-tab>

      <van-tab :title="'全部'" :name="'2'">
        <van-list
            v-model="allLoading"
            :finished="allFinished"
            @load="onLoad"
            finished-text="没有更多了哦~"
            v-if="allListData && allListData.length>0"
        >
          <div class="white_box_1" v-for="(item, index) in allListData" :key="index" @click="routeToDetail(item)">
            <div style="display: flex">
              <div style="flex: 1; font-size: 14px; line-height: 14px; color: #222222;">
                {{ item.taskReservationConfirm ? item.taskReservationConfirm.name : '' }}
              </div>
              <label>{{ handleConfirmState(item.confirmState, item.taskReservationConfirm.state) }}</label>
            </div>

            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
              {{ '待确认人:' }}{{ item.businessCustomerUser ? item.businessCustomerUser.name : '' }}
            </div>

            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
              {{ '开始时间:' }}{{ item.taskReservationConfirm.startTime | moment('yyyy-MM-DD HH:mm:ss') }}
            </div>

            <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 8px;">
              {{ '截止时间:' }}{{ item.taskReservationConfirm.endTime | moment('yyyy-MM-DD HH:mm:ss') }}
            </div>

          </div>
        </van-list>
        <van-empty class="custom-image" v-if="!allListData || allListData.length===0" description="暂无报停餐确认数据"/>
      </van-tab>

    </van-tabs>

  </div>
</template>

<script>
import moment from "moment";
import {post} from "../utils/http";
import Vue from "vue";
import {ACCESS_CustomerUser} from "../store/mutation-types";
import {Toast} from "vant";

export default {
  name: "reservation_record_confirm_list",
  data() {
    return {
      customerUser: null,
      active: null,
      tabTitleList: [{title: '待确认', id: '1'}, {title: '全部', id: '2'}],

      listData: [],
      loading: true,
      finished: false,
      page: 0,

      allListData: [],
      allLoading: true,
      allFinished: false,
      allPage: 0,
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    this.currentCustomerUser = Vue.ls.get(ACCESS_CustomerUser);
    this.fetchData();
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      // 提交确认请求
      this.$router.push('/bundle_calendar');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    fetchData(isFirst = true) {
      this.loading = true;
      this.listData = [];

      if (isFirst) {
        this.page = 0;
      } else {
        this.page++;
      }

      let params = {
        size: 20,
        page: this.page,
        filters: [],
        sort: 'createdTime desc'
      }

      params.filters.push({name: 'confirmState', op: 'in', value: ['UNCONFIRMED','DISSENT_SOLVED','DISSENT_PENDING']});

      post('api/business/v2/taskReservationConfirm/user/search', params).then(res => {
        if (isFirst) {
          this.listData = res.data.rows;
        } else {
          this.listData.push(res.data.rows);
        }

        this.loading = false;

        if (this.listData.length === res.data.total) {
          this.finished = true;
        }

      }).catch(err => {
        this.loading = false;
      });
    },
    fetchAllData(isFirst = true) {
      this.allLoading = true;

      this.allListData = [];

      if (isFirst) {
        this.allPage = 0;
      } else {
        this.allPage++;
      }

      let params = {
        size: 20,
        page: this.allPage,
        filters: [],
        sort: 'createdTime desc'
      }

      post('api/business/v2/taskReservationConfirm/user/search', params).then(res => {
        if (isFirst) {
          this.allListData = res.data.rows;
        } else {
          this.allListData.push(res.data.rows);
        }

        this.allLoading = false;

        if (this.allListData.length === res.data.total) {
          this.allFinished = true;
        }
      }).catch(err => {
        this.allLoading = false;
      });
    },
    onLoad() {
      if (!this.loading) {
        return;
      }
      this.fetchData(false);
    },
    onAllLoad() {
      if (!this.loading) {
        return;
      }
      this.fetchData(false);
    },
    onClick(name) {
      // 重新加载列表
      if (name === '1') {
        this.fetchData();
      }

      if (name === '2') {
        this.fetchAllData();
      }

    },
    routeToDetail(item) {
      if (item.taskReservationConfirm.state === 'NOT_STARTED') {
        Toast('未开始哦，请开始后再来确认~');
        return;
      }
      this.$router.push({
        path: '/reservationRecordConfirm',
        query: {id: item.taskReservationConfirm.id, userId: item.id}
      });
    },
    getDate(time) {
      return moment(time);
    },
    handleConfirmState(confirmState, state) {
      if (state === 'NOT_STARTED') {
        return '未开始';
      }
      switch (confirmState) {
        case 'CONFIRMED':
          return '已确认';
        case 'UNCONFIRMED':
          return '待确认';
        case 'DISSENT_PENDING':
          return '异议待处理';
        case 'DISSENT_SOLVED':
          return '异议已处理';
        case 'DISSENT_REFUSE':
          return '异议已拒绝';
        default:
          return '-';
      }
    },
  }
}
</script>

<style scoped>
.white_box_1 {
  width: 93vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1pt 2.67pt #ededed;
  overflow: hidden;
  margin-top: 3vw;
  padding: 12px;
}
</style>